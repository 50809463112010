import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { Button, Popper, Grow, Paper, ClickAwayListener, Menu, MenuItem } from '@mui/material';

import ModalPDPA from './moadalPDPA';
import Logo from '../assets/images/HD.png';
import ModalLogin from '../components/modalLogin';
import ImgCloud from '../assets/images/cloud.png';
import BannerSub from '../assets/images/Image_51.png';
import BannerNews from '../assets/images/Image_41.png';
import BannerAbout from '../assets/images/Image_25.png';
import ModalSystemManagement from './modalSystemManagement';
import HomeIconActive from '../assets/images/Image_16.png';
import TopicIconActive from '../assets/images/Image_48.png';
import HomeIconInactive from '../assets/images/Image_24.png';
import HilightIconActive from '../assets/images/Image_47.png';
import TopicIconInactive from '../assets/images/Image_18.png';
import OtherIconInactive from '../assets/images/Image_19.png';
import OtherIconActive from '../assets/images/Image_26_ix.png';
import HilightIconInactive from '../assets/images/Image_17.png';
import IconServiceInactive from '../assets/images/Image_62.png';
import IconServiceActive from '../assets/images/services-black.png';
import BannerMain from '../assets/images/village-in-the-forest-with-sun.png';
import { useGetPublicMenuQuery, useGetMenuQuery, useGetPublicTopicQuery, useLogoutMutation, useGetAuthUserQuery, useGetPrivateTopicQuery } from '../services/main';

const BASE_NAME = process.env.REACT_APP_BASE_NAME;

const Header = () => {
    const authUser = useSelector(state=>state.authUser.value);
    const navigate = useNavigate();
    const topicMenuRef = useRef(null);
    const serviceMenuRef = useRef(null);
    const highlightMenuRef = useRef(null);
    const otherMenuRef = useRef(null);
    const [logout] = useLogoutMutation();
    const { data: publicTopicData } = useGetPublicTopicQuery(null, { skip: authUser?.token });
    const { data: privateTopicData } = useGetPrivateTopicQuery(null, { skip: !authUser?.token });
    const { refetch: authRefetch } = useGetAuthUserQuery();
    const { data: privateMenuData } = useGetMenuQuery(null, { skip: !authUser?.token });
    const { data: publicMenuData } = useGetPublicMenuQuery(null, { skip: authUser?.token });

    const menuData = privateMenuData || publicMenuData;
    const menuList = menuData?.main || [];
    const topicList = privateTopicData?.topics || publicTopicData?.topics || [];
    const highlightList = privateTopicData?.highlights || publicTopicData?.highlights || [];
    const isHomePage = BASE_NAME ? (window.location.pathname === BASE_NAME) || (window.location.pathname === BASE_NAME+"/") : (window.location.pathname === "/");
    const isHomeAbout = window.location.pathname === (BASE_NAME+"/about");
    const isNewsPage = window.location.pathname === (BASE_NAME+"/news");

    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleProfileClose = () => {
        setAnchorEl(null);
    };

    const [otherID, setOtherID] = useState(0);
    const [serviceID, setServiceID] = useState(0);
    const [topicOpen, setTopicOpen] = useState(false);
    const [otherOpen, setOtherOpen] = useState(false);
    const [serviceOpen, setServiceOpen] = useState(false);
    const [modalPDPAOpen, setModalPDPAOpen] = useState(false);
    const [highlightOpen, setHighlightOpen] = useState(false);
    const [isModalLoginOpen, setIsModalLoginOpen] = useState(false);
    const [isModalSystemOpen, setIsModalSystemOpen] = useState(false);
    
    useEffect(() => {
        if(!isModalLoginOpen && authUser?.token && !authUser?.email){
            authRefetch();
        }else if(!isModalLoginOpen && authUser?.token && authUser?.email && !authUser?.acceptPDPA){
            setModalPDPAOpen(true)
        }
    }, [isModalLoginOpen, authUser])

    const genMenuClass = (link) => {
        const getLink = (link === "/") ? [BASE_NAME, BASE_NAME+"/"] : [link];
        if(link === BASE_NAME+"/about"){
            getLink.push(BASE_NAME+"/news")
        }

        if(getLink.includes(window.location.pathname)){
            return "p-6 hover:bg-white flex items-center hover:text-menu main-menu activee"
        }
        return "p-6 hover:bg-white flex items-center hover:text-menu main-menu"
    }

    const handleMenuClick = (menuTitile, link) => {
        clearSubMenu()
        navigate(link)
    }

    const clearSubMenu = () => {
        handleCloseTopic();
        handleCloseService();
        handleCloseHighlight();
        handleCloseOther();
    }

    const handleOpenHighlight = () => {
        if(!highlightOpen){
            handleCloseTopic();
            handleCloseService();
            setHighlightOpen(true);
            handleCloseOther();
        }
    };
    
    const handleCloseHighlight = () => {
        if(highlightOpen){
            setHighlightOpen(false);
        }
    };

    const handleOpenTopic = () => {
        if(!topicOpen){
            handleCloseService();
            handleCloseHighlight();
            setTopicOpen(true);
            handleCloseOther();
        }
    };

    const handleCloseTopic = () => {
        if(topicOpen){
            setTopicOpen(false);
        }
    };

    const handleOpenService = () => {
        if(!serviceOpen){
            handleCloseTopic();
            handleCloseHighlight();
            setServiceOpen(true);
            handleCloseOther();
        }
    };

    const handleCloseService = () => {
        if(serviceOpen){
            setServiceOpen(false);
        }
    };

    const handleOpenOther = () => {
        if(!otherOpen){
            handleCloseTopic();
            handleCloseHighlight();
            setServiceOpen(false);
            setOtherOpen(true);
        }
    };

    const handleCloseOther = () => {
        if(otherOpen){
            setOtherOpen(false);
        }
    };

    const handleLogout = () => {
        handleProfileClose()
        logout()
    }

    const HoverMenu = (type, menuListSource, handleClose, TransitionProps) => {
        const genList = [...menuListSource].sort((a, b) => {
            if(a.dashboards.length > b.dashboards.length){
                return -1;
            } else if(b.dashboards.length < a.dashboards.length) {
                return 1;
            }

            return 0;
        })

        return(
            <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                <Paper className='max-w-xl w-11/12 mx-auto p-4 shadow-xl'>
                    <ClickAwayListener onClickAway={handleClose}>
                        <div className={`grid grid-cols-3 ${type === 'topic' ? "gap-4" : ""}`}>
                            {genList?.map((item, itemIndex) => {
                                const sublist = item?.dashboards || [];
                                return (
                                    <div key={`${type}-item-${itemIndex}`} className={(type === 'topic') || (((itemIndex+1)%3) === 0) ? "" : "border-r border-mainBtn mr-2"}>
                                        {type === 'topic' ? 
                                            <span className='text-xl font-bold'>
                                                {item['title']}
                                            </span>
                                        : null}
                                        {sublist.map((sub, subIndex) => {
                                            const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                                            return (
                                                <a key={`${type}-sub-item-${subIndex}`} className={`flex text-top group p-1 ${(type === 'topic') || (((itemIndex+1)%3) === 0) ? "" : "mr-2"} ${isUrl ? "cursor-pointer hover:text-white hover:bg-mainBtn" : ""}`} href={isUrl ? sub['url'] : "#"} target={isUrl ? "_blank" : "_self"}>
                                                    <div className='rounded-full bg-mainBtn group-hover:bg-white w-4 h-4 mt-1.5' style={{minHeight: '1rem', minWidth: '1rem'}}></div>
                                                    <span className='pl-2 text-xl'>
                                                        {sub['title']}
                                                    </span>
                                                </a>
                                            )
                                        })}
                                        {type === 'topic' ? 
                                            <div className='bg-mainBtn h-px mt-2'></div>
                                        : null}
                                    </div>
                                )
                            })}
                        </div>
                    </ClickAwayListener>
                </Paper>
            </Grow>
        )
    }

    const renderPopOverServiceAndOther = (itemID, isOpen = false, itemEL = null) => {
        return(
            <Popper open={isOpen} anchorEl={itemEL} transition disablePortal onMouseLeave={handleCloseService} className='w-full cursor-default zIndex-999'>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                        <Paper className='max-w-xl w-11/12 mx-auto p-4 shadow-xl'>
                            <ClickAwayListener onClickAway={handleCloseService}>
                                <div className="grid grid-cols-3 gap-4">
                                    {[...menuData.level1].sort((a, b) => (menuData.level2.filter(level2 => level2['parentId'] === a['id'])?.length < menuData.level2.filter(level2 => level2['parentId'] === b['id'])?.length) ? -1 : 0).filter(item => item['parentId'] === itemID)?.map((item, itemIndex) => {
                                        const sublist = menuData?.level2 ? menuData.level2.filter(level2 => level2['parentId'] === item['id']) : [];
                                        const isMainUrl = item['url'] && ((item['url']?.indexOf('http') === 0) || (item['url']?.indexOf('www.') === 0));
                                        let getPageUrl;
                                        if(item['url'] === "/news"){
                                            getPageUrl = "/news";
                                        }else if(item['url'] === "/project-detail"){
                                            getPageUrl ='/about'
                                        }

                                        return(
                                            <div key={`service-item-${itemIndex}`} className={"cursor-pointer"} onClick={() => getPageUrl ? handleMenuClick(null, getPageUrl) : window.open(isMainUrl ? item['url'] : "#", isMainUrl ? "_blank" : "_self")}>
                                                <span className='text-xl font-bold'>
                                                    {item['title']}
                                                </span>
                                                {sublist.map((sub, subIndex) => {
                                                    const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                                                    return (
                                                        <a key={`service-sub-item-${subIndex}`} className={`flex text-top group p-1 ${isUrl ? "cursor-pointer hover:text-white hover:bg-mainBtn" : ""}`} href={isUrl ? sub['url'] : "#"} target={isUrl ? "_blank" : "_self"}>
                                                            <div className='rounded-full bg-mainBtn group-hover:bg-white w-4 h-4 mt-1.5'></div>
                                                            <span className='pl-2 text-xl'>
                                                                {sub['title']}
                                                            </span>
                                                        </a>
                                                    )
                                                })}
                                                <div className='bg-mainBtn h-px mt-2'></div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>    
                )}
            </Popper>
        )
    }

    return (
        <div className='bg-gray-900 bg-no-repeat bg-top bg-cover pt-6' style={{  width: '100%', backgroundImage: isHomePage ? `url(${ImgCloud}), url(${BannerMain})` : isHomeAbout ? `url(${BannerAbout})` : isNewsPage ? `url(${BannerNews})` : `url(${BannerSub})` }}>
            <div className='bg-no-repeat' style={{ width: '100%', backgroundImage: isHomePage ? `url(${ImgCloud})` : "", backgroundPosition: 'center', backgroundSize: "99%" }}>
                <div className='flex justify-center items-center max-w-xl w-11/12 mx-auto px-4' onMouseOver={clearSubMenu}>
                    <div className='flex-grow flex items-center flex-row'>
                        <img alt='headerimage-logo' src={Logo} />
                        <div className='flex flex-col text-white font-bold text-2xl leading-5 pl-2'>
                            <span>กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม</span>  
                            <span>Ministry of Natural Resources and Environment</span>  
                        </div>
                    </div>
                    <div className='text-white flex'>
                        {authUser?.token && authUser?.isAdmin ? 
                            <Button variant='contained' color='adminBtn' style={{ marginRight: 10, fontSize: '1rem', fontWeight: 'bold' }} onClick={() => setIsModalSystemOpen(true)}>
                                <div>System management</div>
                            </Button>
                        : null}
                        <Button id="profile-button" variant='contained' color='adminBtn' className='flex flex-col font-bold text-lg leading-5' style={{ fontSize: '1rem', fontWeight: 'bold' }} aria-controls={menuOpen ? 'profile-menu' : undefined} aria-haspopup="true" aria-expanded={menuOpen ? 'true' : undefined} onClick={(e) => authUser?.token ? handleProfileClick(e) : setIsModalLoginOpen(true)}>
                            <div><Groups2OutlinedIcon fontSize='large' /></div>
                            {authUser?.token ? 
                                <div>{authUser.displayName}</div>
                            :
                                <React.Fragment>
                                    <div>สำหรับ</div>
                                    <div>เจ้าหน้าที่ ทส.</div>
                                </React.Fragment>
                            }
                        </Button>
                        <Menu id="profile-menu" anchorEl={anchorEl} open={menuOpen} onClose={handleProfileClose} MenuListProps={{ 'aria-labelledby': 'profile-button' }} className="text-xl" style={{ width: '100%', fontSize: '2rem' }}>
                            <MenuItem onClick={() => navigate('/profile')} style={{ fontSize: '1.2rem', fontWeight: 'bold', minWidth: '100%' }}>
                                ดูโปรไฟล์
                            </MenuItem>
                            <MenuItem onClick={handleLogout} style={{ fontSize: '1.2rem', fontWeight: 'bold', minWidth: '100%' }}>
                                ออกจากระบบ
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                {isHomePage ? 
                    <div className='my-4 max-w-xl w-11/12 mx-auto pt-10 pb-4 md:py-24 lg:py-40 xl:py-48 text-white text-5xl lg:text-7xl flex flex-col text-center font-bold' style={{ borderTop: '2px solid #647e4b' }} onMouseOver={clearSubMenu}>
                        <span>
                            ระบบเชื่อมโยงและแสดงข้อมูล
                        </span>
                        <span>
                            ทรัพยากรธรรมชาติและสิ่งแวดล้อม
                        </span>
                    </div>
                : 
                    <div className='py-24'></div>
                }
                <div className={`w-full ${isHomePage ? "" : "bg-menu"}`}>
                    <div className={`max-w-xl mx-auto flex bg-menu font-bold text-base lg:text-xl cursor-pointer text-white`}>
                        <div onClick={() => handleMenuClick("หน้าแรก", "/")} className={`${genMenuClass("/")}`} onMouseOver={clearSubMenu}>
                            <img alt='headerimage-HomeIconActive' className='forActive' src={HomeIconActive} />
                            <img alt='headerimage-HomeIconInactive' className='forInactive' src={HomeIconInactive} />
                            <span className='pt-2 pl-2'>หน้าแรก</span>
                        </div>
                        <div onClick={() => handleMenuClick("รายงานเด่นด้านทรัพยากรธรรมชาติ", '/highlight')} className={`${genMenuClass(BASE_NAME+"/highlight")} flex-1`} ref={highlightMenuRef} onMouseOver={handleOpenHighlight}>
                            <img alt='headerimage-HilightIconActive' className='forActive' src={HilightIconActive} />
                            <img alt='headerimage-HilightIconInactive' className='forInactive' src={HilightIconInactive} />
                            <span className='pl-2'>
                                รายงานเด่นด้านทรัพยากรธรรมชาติ<br />และสิ่งแวดล้อม
                            </span>
                        </div>
                        <div onClick={() => handleMenuClick("รายงานด้านทรัพยากรธรรมชาติ", '/topic')} className={`${genMenuClass(BASE_NAME+"/topic")} flex-1`} ref={topicMenuRef} onMouseOver={handleOpenTopic}>
                            <img alt='headerimage-TopicIconActive' className='forActive' src={TopicIconActive} />
                            <img alt='headerimage-TopicIconInactive' className='forInactive' src={TopicIconInactive} />
                            <span className='pl-2'>
                                รายงานด้านทรัพยากรธรรมชาติ<br />และสิ่งแวดล้อม
                            </span>
                        </div>
                        {menuList?.map((menu, index) => {
                            const isService = (menu['title'] === 'บริการข้อมูล');
                            const isOther = (menu['title'] === 'อื่นๆ');
                            const genLink = (menu['title'] === 'อื่นๆ') ? "/about" : "#";
                            if(isService && (serviceID !== menu?.id)){
                                setServiceID(menu?.id);
                            }else if(isOther && (otherID !== menu?.id)){
                                setOtherID(menu?.id)
                            }
                            return (
                                <div key={`menu-${index}`} onClick={() => handleMenuClick(menu['title'], genLink)} className={`${genMenuClass(BASE_NAME+genLink)}`} ref={isService ? serviceMenuRef : isOther ? otherMenuRef : null} onMouseOver={isService ? handleOpenService : isOther ? handleOpenOther : clearSubMenu}>
                                    {isService ?
                                        <>
                                            <img alt='headerimage-IconServiceActive' className='forActive' src={IconServiceActive} />
                                            <img alt='headerimage-IconServiceInactive' className='forInactive' src={IconServiceInactive} />
                                        </>
                                    : 
                                        <>
                                            <img alt='headerimage-OtherIconActive' className='forActive' src={OtherIconActive} />
                                            <img alt='headerimage-OtherIconInactive' className='forInactive' src={OtherIconInactive} />
                                        </>
                                    }
                                    <span className='pl-2'>
                                        {menu['title']}
                                    </span>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            {highlightList && highlightList?.length ?
                <Popper open={highlightOpen} anchorEl={highlightMenuRef.current} transition disablePortal onMouseLeave={handleCloseHighlight} className='w-full cursor-default zIndex-999'>
                    {({ TransitionProps }) => HoverMenu('highlight', highlightList, handleCloseHighlight, TransitionProps)}
                </Popper>
            : null}
            {topicList && topicList?.length ?
                <Popper open={topicOpen} anchorEl={topicMenuRef.current} transition disablePortal onMouseLeave={handleCloseTopic} className='w-full cursor-default zIndex-999'>
                    {({ TransitionProps }) => HoverMenu('topic', topicList, handleCloseTopic, TransitionProps)}
                </Popper>
            : null}
            {serviceID && menuData?.level1 && menuData?.level1?.length && menuData.level1.find(item => item['parentId'] === serviceID) ?
                renderPopOverServiceAndOther(serviceID, serviceOpen, serviceMenuRef.current)
            : null}
            {otherID && menuData?.level1 && menuData?.level1?.length && menuData.level1.find(item => item['parentId'] === otherID) ?
                renderPopOverServiceAndOther(otherID, otherOpen, otherMenuRef.current)
            : null}
            <ModalSystemManagement open={isModalSystemOpen} handleClose={() => setIsModalSystemOpen(false)} />
            <ModalLogin open={isModalLoginOpen} handleClose={() => setIsModalLoginOpen(false)} />
            <ModalPDPA open={modalPDPAOpen} handleClose={() => setModalPDPAOpen(false)} />
        </div>
    );
};

export default Header;
