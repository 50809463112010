import React from 'react';
import { Routes, Route } from "react-router-dom";

import AllRoutes from '../configs/AllRoutes';

const Router = (props) => {
    return (
        <Routes>
            {AllRoutes?.map(routeObj => (
                <Route exact path={routeObj.url} key={routeObj.url || routeObj.page} element={!routeObj.layout ? 
                    <routeObj.page {...props} />
                    :
                    <routeObj.layout menuActiveKey={routeObj['activeKey']}><routeObj.page menuActiveKey={routeObj['activeKey']}/>
                </routeObj.layout>} />
            ))}
        </Routes>
    );
};

export default Router;