import { useState, Fragment } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

import DateObject from '../utils/DateObject';
import AboutMenu from '../components/aboutMenu';
import { useGetNewsListQuery } from '../services/main';

const NewsPage = () => {
    const [newsItem, setNewsItem] = useState(null);
    const isSMDown = useMediaQuery(theme=>theme.breakpoints.down('sm'));
    const isSMUp = useMediaQuery(theme=>theme.breakpoints.up('sm'));
    const isMDDown = useMediaQuery(theme=>theme.breakpoints.down('md'));
    const isMD = useMediaQuery(theme=>theme.breakpoints.up('md'));
    const isLGUp = useMediaQuery(theme=>theme.breakpoints.up('lg'));
    const { data: newData } = useGetNewsListQuery();
    const newList = newData?.news || [];

    return (
        <div className={`max-w-xl mx-auto ${isMD ? "pt-6" : "pt-0"}`}>
            {isMD ? <AboutMenu newsTitle={newsItem?.title} handleOnNewsClick={() => setNewsItem(null)} /> : null}
            {newsItem && isMD ? null : (
                <div className={`${isMD ? 'text-6xl' : 'text-4xl'} font-bold ${!isMD ? "cursor-pointer" : ""}`} onClick={() => setNewsItem(null)}>
                    ข่าวประชาสัมพันธ์
                </div>    
            )}
            {!isMD ? <div className="border-t-2 border-dashed my-2" style={{borderColor: 'rgba(112,112,112,1)'}}></div> : null}
            {newsItem ? 
                <div className='pt-3'>
                    <img src={newsItem['image']} className="w-full rounded-3xl object-cover" style={{ maxHeight: isLGUp ? '600px' : isMD ? '400px' : '300px' }} />
                    <div className={`${isMD ? "text-6xl" : "text-4xl"} border-l-8 border-adminBtn pl-4 mt-5 font-bold`}>
                        {newsItem['title']}
                    </div>
                    <div className='border-t-2 my-6 border-adminBtn'></div>
                    <div dangerouslySetInnerHTML={{__html: newsItem['content']}} className={`${isMDDown ? 'text-2xl' : "text-3xl"}`}></div>
                </div>
            : !newList || !newList?.length ? 
                <div className={`w-full text-center py-6 mb-24 ${isMD ? 'text-4xl' : 'text-2xl'}`}>
                    <span>
                        ไม่พบข้อมูล
                    </span>
                </div>
            : (
                <Grid container spacing={isMDDown && !isSMDown ? 2 : 4} className="pt-6">
                    {newList?.map((item, index) => {
                        if(!index && isMD){
                            return (
                                <Fragment key={`news-item-${index}-0`}>
                                    <Grid item xs={6} className="rounded-3xl cursor-pointer" onClick={() => setNewsItem(item)}>
                                        <div className='w-full bg-center bg-no-repeat bg-cover rounded-3xl' style={{ backgroundImage: `url(${item['image']})`, height: '100%' }}>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} className="cursor-pointer" onClick={() => setNewsItem(item)}>
                                        <span className={`${isMDDown ? 'text-3xl' : 'text-4xl'}`}>
                                            {DateObject.convertDateTime(item['publishDate'], 'DD Mon yyyy')}
                                        </span>
                                        <div className='border-t-2 border-black my-2'></div>
                                        <span className={`${isMDDown ? 'text-4xl' : 'text-5xl'} font-bold text-3line`}>
                                            {item['title']}
                                        </span>
                                        <div dangerouslySetInnerHTML={{__html: item['content']}} className={`${isMDDown ? 'text-xl' : "text-2xl"} text-5line pt-2`}></div>
                                    </Grid>
                                </Fragment>    
                            )
                        } else {
                            return (
                                <Grid item xs={12} sm={6} lg={4} className="cursor-pointer" key={`news-item-${index}-1`} onClick={() => setNewsItem(item)}>
                                    <div className='w-full bg-center bg-no-repeat bg-cover rounded-3xl mb-4' style={{ backgroundImage: `url(${item['image']})`, height: '300px' }}></div>
                                    <span className={`${isSMDown ? 'text-2xl' : 'text-3xl'}`}>
                                        {DateObject.convertDateTime(item['publishDate'], 'DD Mon yyyy')}
                                    </span>
                                    <div className='border-t-2 border-black my-2'></div>
                                    <span className={`${isMDDown ? 'text-3xl' : 'text-4xl'} font-bold text-3line`}>
                                        {item['title']}
                                    </span>
                                    {isSMDown ? <div className="border-t-2 border-dashed mt-4" style={{borderColor: 'rgba(112,112,112,1)'}}></div> : null}
                                </Grid>    
                            )
                        }
                    })}
                </Grid>
            )}
        </div>
    );
}

export default NewsPage;