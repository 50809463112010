import HomeLayout from '../layouts/HomeLayout';

import HomePage from '../pages/Home';
import NewsPage from '../pages/News';
import TopicPage from '../pages/Topic';
import AboutPage from '../pages/About';
import ProfilePage from '../pages/Profile';
import NotFoundPage from '../pages/NotFound';
import HighlightPage from '../pages/Highlight';

const components = {
    home: {
        key: "home",
        isFirstPage: true,
        activeKey: "home",
        url: "/",
        page: HomePage,
        layout: HomeLayout,
    },
    news: {
        key: "news",
        activeKey: "news",
        url: "/news",
        page: NewsPage,
        layout: HomeLayout,
    },
    about: {
        key: "about",
        activeKey: "about",
        url: "/about",
        page: AboutPage,
        layout: HomeLayout,
    },
    topic: {
        key: "topic",
        activeKey: "topic",
        url: "/topic",
        page: TopicPage,
        layout: HomeLayout,
    },
    highlight: {
        key: "highlight",
        activeKey: "highlight",
        url: "/highlight",
        page: HighlightPage,
        layout: HomeLayout,
    },
    profile: {
        key: "profile",
        activeKey: "profile",
        url: "/profile",
        page: ProfilePage,
        layout: HomeLayout,
    },
    resetPassword: {
        key: "resetPassword",
        activeKey: "resetPassword",
        url: "/reset-password",
        page: ProfilePage,
        layout: HomeLayout,
    },
    notFound: {
        key: "pageNotFound",
        activeKey: "pageNotFound",
        url: "*",
        page: NotFoundPage,
        layout: null
    }
};

const {
    home,
    news,
    about,
    topic,
    highlight,
    profile,
    resetPassword,
    notFound,
} = components;

const router = [home, news, about, topic, highlight, profile, resetPassword, notFound];
export default router;

let defaultUrl = '/';
for(const key in components) {
    const pageObj = components[key];
    if(pageObj.hasOwnProperty('isFirstPage') && pageObj['isFirstPage']) {
        defaultUrl = pageObj['url'];
        break;
    }
}
export const firstPageUrl = defaultUrl;
