import { Divider } from '@mui/material';
import Logo from '../assets/images/HD_bk@2x.png';

const Footer = () => {
    return (
        <div className='w-full pt-6'>
            <div className='text-2xl px-2 font-bold text-adminBtn text-center'>
                หมายเหตุ: ข้อมูลในเว็บไซต์ไม่สามารถนำไปใช้อ้างอิงทางกฎหมายได้
            </div>
            <div className="w-full text-white px-4 pt-8 pb-4 mt-6" style={{backgroundColor: "rgba(36,51,48,1)"}}>
                <div className="max-w-md mx-auto">
                    <div className="flex flex-col md:flex-row divide-y md:divide-y-0 md:divide-x text-2xl font-bold">
                        <div className="pr-0 pb-4 md:pb-0 md:pr-4 cursor-pointer" onClick={()=>window.open('http://www.mnre.go.th/th/policy/content/8','_blank')}>
                            นโยบายความปลอดภัย
                        </div>
                        <div className="px-0 py-4 md:py-0 md:px-4 cursor-pointer" onClick={()=>window.open('http://www.mnre.go.th/th/policy/content/1049','_blank')}>
                            นโยบายการคุ้มครองส่วนบุคคล
                        </div>
                        <div className="pl-0 pt-4 md:pt-0 md:pl-4 cursor-pointer" onClick={()=>window.open('http://www.mnre.go.th/th/policy/content/2198','_blank')}>
                            นโยบายเว็บไซต์และการปฏิเสธความรับผิดชอบ
                        </div>
                    </div>
                    <div className='pt-16'></div>
                    <div className='flex flex-col md:flex-row'>
                        <div className='flex items-center divide-x'>
                            <div className='pr-4'>
                                <img src={Logo} style={{minWidth: '90px', width: '90px'}} />
                            </div>
                            <div className='font-bold pl-4 text-2xl' style={{ lineHeight: '1.5rem'}}>
                                กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม<br />
                                Ministry of Natural Resources and Environment<br />
                                Copyright 2022 กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม<br />
                                All Rights Reserved.<br />
                            </div>
                        </div>
                        <Divider className='pt-10 block md:hidden' style={{borderColor: 'white'}} />
                        <div className='flex items-center md:pl-4'>
                            <div className='font-bold text-6xl text-end' style={{color: 'rgba(161,177,137,1)', lineHeight: '2rem'}}>
                                <div>
                                    Green
                                </div>
                                <div>
                                    Call
                                </div>
                            </div>
                            <div style={{fontSize: '7rem'}}>
                                1310
                            </div>
                        </div>
                    </div>
                </div>
            </div>    
        </div>
    );
};

export default Footer;