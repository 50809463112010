import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { openSnackbar } from '../reducers/notify';
import { setAuthUser, clearAuthUser } from '../reducers/authUser';

const API_URL = "https://naturebi.mnre.go.th/api/";

export const mainService = createApi({
    reducerPath: 'mainService',
    baseQuery: fetchBaseQuery({ 
        baseUrl: API_URL,
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("wktefy");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
        
            return headers
        },
    }),
    tagTypes: ['AUTHUSER'],
    endpoints: (builder) => ({
        getPublicMenu: builder.query({
            query: () => `get-public-menu`,
            transformResponse: (response) => response.data,
        }),
        getMenu: builder.query({
            query: () => `get-menu`,
            transformResponse: (response) => response.data,
        }),
        getPrivateTopic: builder.query({
            query: () => `get-topics`,
            transformResponse: (response) => response.data,
        }),
        getPublicTopic: builder.query({
            query: () => `get-public-topics`,
            transformResponse: (response) => response.data,
        }),
        getNewsList: builder.query({
            query: () => `get-news`,
            transformResponse: (response) => response.data,
        }),
        login: builder.mutation({
            query: (initialPost) => ({ url: 'check-login', method: 'POST', body: initialPost }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if(data.success){
                        localStorage.setItem('wktefy', data?.data?.token);
                        localStorage.setItem('displayName', data?.data?.displayName);
                        const genAuthData = {
                            token: data?.data?.token,
                            displayName: data?.data?.displayName
                        }
                        dispatch(setAuthUser(genAuthData));
                    }else{
                        dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
                        dispatch(clearAuthUser());
                    }
                } catch(e) {
                    dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
                    dispatch(clearAuthUser());
                }
            }
        }),
        logout: builder.mutation({
            query: () => ({ url: 'remove-token', method: 'POST' }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                dispatch(clearAuthUser());
            }
        }),
        getAuthUser: builder.query({
            query: () => ({ url: 'get-user-profile', headers: { 'authorization': `Bearer ${localStorage.getItem("wktefy")}` } }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    if(data.success){
                        const genAuthData = {
                            email: data?.data?.userProfile?.email || null,
                            firstName: data?.data?.userProfile?.firstName || null,
                            lastName: data?.data?.userProfile?.lastName || null,
                            title: data?.data?.userProfile?.title || null,
                            deptId: data?.data?.userProfile?.deptId,
                            telNo: data?.data?.userProfile?.telNo || null,
                            acceptPDPA: data?.data?.userProfile?.acceptPDPA,
                            newsSubscribe: data?.data?.userProfile?.newsSubscribe,
                            isAdmin: data?.data?.userProfile?.isAdmin
                        }
                        dispatch(setAuthUser(genAuthData));
                    }else{
                        dispatch(clearAuthUser());
                    }
                } catch(e) {
                    dispatch(clearAuthUser());
                }
            }
        }),
        forgotPassword: builder.mutation({
            query: (initialPost) => ({ url: 'send-reset-password', method: 'POST', body: initialPost }),
        }),
        getDepartment: builder.query({
            query: () => `get-all-departments`,
            transformResponse: (response) => response.data,
        }),
        register: builder.mutation({
            query: (initialPost) => ({ url: 'add-user', method: 'POST', body: initialPost }),
        }),
        updateProfile: builder.mutation({
            query: (initialPost) => ({ url: 'update-user-profile', method: 'POST', body: initialPost }),
        }),
        updatePassword: builder.mutation({
            query: (initialPost) => ({ url: 'update-password', method: 'POST', body: initialPost }),
        }),
        forceUpdatePassword: builder.mutation({
            query: ({header, data}) => ({ url: 'update-password', headers: {authorization: header}, method: 'POST', body: data }),
        }),
        savePDPA: builder.mutation({
            query: () => ({ url: 'save-pdpa', method: 'POST' }),
        }),
    })
})

export const { useGetPublicMenuQuery, useGetMenuQuery, useGetPrivateTopicQuery, useGetPublicTopicQuery, useGetNewsListQuery, useLoginMutation, useLogoutMutation, useGetAuthUserQuery, useForgotPasswordMutation, useGetDepartmentQuery, useRegisterMutation, useUpdateProfileMutation, useUpdatePasswordMutation, useForceUpdatePasswordMutation, useSavePDPAMutation } = mainService;
