import { useNavigate } from "react-router-dom";

const AboutMenu = ({newsTitle = "", handleOnNewsClick = () => {} }) => {
    const navigate = useNavigate();
    const locationPath = window.location.pathname;

    return (
        <>
            <div className='flex text-4xl'>
                <div className="flex">
                    <div className={locationPath.includes('/about') ? 'font-bold cursor-pointer text-1line' : 'font-base cursor-pointer text-1line'} onClick={() => navigate('/about')}>
                        รายละเอียดโครงการ
                    </div>
                    <div className="w-px mx-4" style={{backgroundColor: 'rgba(112,112,112,1)'}}></div>
                    <div className={locationPath.includes('/news') && !newsTitle ? 'font-bold cursor-pointer text-1line' : 'font-base cursor-pointer text-1line'} onClick={() => newsTitle ? handleOnNewsClick() : navigate('/news')}>
                        ข่าวประชาสัมพันธ์
                    </div>
                </div>
                {newsTitle ? (
                    <>
                        <div className="w-px mx-4" style={{backgroundColor: 'rgba(112,112,112,1)'}}></div>
                        <div className={'font-bold text-1line'}>
                            {newsTitle}
                        </div>
                    </>
                ): null}
            </div>
            <div className="border-t-2 border-dashed my-8" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
        </>
    )
}

export default AboutMenu;