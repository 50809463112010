import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import Logo from '../assets/images/HD@2x.png';

const NotFoundPage = () => {
    const navigate = useNavigate();

    return (
        <div className='flex min-h-screen justify-center px-4'>
            <div className='my-auto flex flex-col md:flex-row max-w-screen-md py-6'>
                <div className='mr-0 md:mr-4 py-2 max-w-sm' >
                    <img alt='dg-page-not-found' src={Logo} />
                </div>
                <div className='flex flex-col my-auto'>
                    <span className='text-5xl font-bold'>{"ไม่พบหน้า"}</span>
                    <span className='pt-2 py-4 text-2xl'>{"ขออภัยไม่พบหน้าที่ท่านกำลังเข้าถึง"}</span>
                    <Button variant='contained' className='py-4 px-6 w-max' sx={{ fontSize: '1.2rem', fontWeight: 'bold' }} onClick={()=>navigate("/")}>{"กลับหน้าแรก"}</Button>
                </div>
            </div>
        </div>
    );
}

export default NotFoundPage;