import { useState } from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { Grid, useMediaQuery } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import { useGetPublicTopicQuery, useGetPrivateTopicQuery } from '../services/main';

import ImgWave from "../assets/images/wave.png";
import ImgDNA from "../assets/images/Image_14.png";
import ImgOther from "../assets/images/Image_19.png";
import ImgCloud from "../assets/images/Image_13.png";
import ImgForest from "../assets/images/Image_11.png";
import ImgTurtle from "../assets/images/Image_12.png";
import BannerTopic from '../assets/images/topicbanner.png';

const TopicPage = () => {
    const isMD = useMediaQuery(theme=>theme.breakpoints.up('md'));
    const authUser = useSelector(state=>state.authUser.value);
    const { data: publicTopicData, isLoading: isPublicLoading } = useGetPublicTopicQuery(null, { skip: authUser?.token });
    const { data: privateTopicData, isLoading: isPrivateLoading } = useGetPrivateTopicQuery(null, { skip: !authUser?.token });
    const isLoading = isPrivateLoading || isPublicLoading;

    const topicList = privateTopicData?.topics || publicTopicData?.topics || [];
    const [subActiveMenuID, setSubActiveMenuID] = useState(0);

    const genList = [...topicList].sort((a, b) => {
        if(a.dashboards.length > b.dashboards.length){
            return -1;
        } else if(b.dashboards.length < a.dashboards.length) {
            return 1;
        }

        return 0;
    })

    return (
        <div className="max-w-xl mx-auto pt-6">
            <div className="md:border-l-8 border-adminBtn text-4xl md:text-6xl font-bold pl-2 mb-8" style={{lineHeight: isMD ? '3.2rem' : "2rem"}}>
                รายงานด้านทรัพยากรธรรมชาติ<br />
                และสิ่งแวดล้อม
            </div>
            <Grid container spacing={4} >
                {isLoading ?  [...Array(3)].map((item, index) => { 
                    return (
                        <Grid key={`topic-item-load-${index}`} item xs={12} md={4}>
                            <div className='w-full bg-gray-200 rounded-lg mb-2'>
                                <span className='text-primary font-bold text-2xl text-transparent'>
                                    PePatcha
                                </span>
                            </div>
                            {[...Array(3)].map((sub, subIndex) => {
                                return (
                                    <div key={`topic-sub-item-load-${subIndex}`} className={`flex px-0 md:px-2 py-1 items-center text-xl sm:text-2xl bg-white border-menu border-opacity-30`}>
                                        <div className='rounded-full bg-adminBtn mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                        <span className='ml-2 text-transparent w-full bg-gray-200 rounded-lg text-sm'>
                                            PePatcha
                                        </span>
                                    </div>
                                )
                            })}
                            <div className='border-b-2 border-adminBtn pt-2 w-11/12 mx-auto'></div>
                        </Grid>     
                    )
                }) : genList && genList.length ? genList?.map((item, itemIndex) => {
                    const sublist = item?.dashboards || [];
                    return (
                        <Grid key={`topic-item-${itemIndex}`} item xs={12} md={6} lg={4}>
                            <div className='flex items-center pb-2'>
                                <div className='bg-primary p-2 mr-2 rounded-full flex items-center justify-center' style={{height: '2.5rem', width: '2.5rem'}}>
                                    <img alt="topic-forest" src={item['title'] === "ด้านทรัพยากรทางบก" ? ImgForest : item['title'] === "ด้านทรัพยากรน้ำ" ? ImgWave : item['title'] === "ด้านสิ่งแวดล้อม" ? ImgCloud : item['title'] === "ด้านความหลากหลายทางชีวภาพ" ? ImgDNA : item['title'] === "ด้านทรัพยากรทางทะเลและชายฝั่ง" ? ImgTurtle : ImgOther} />
                                </div>
                                <span className='text-primary font-bold text-2xl flex-grow'>
                                    {item['title']}
                                </span>
                                {sublist && sublist?.length && (subActiveMenuID === item?.id) && !isMD ? 
                                    <RemoveIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(0)}} />
                                : sublist && sublist?.length && (subActiveMenuID !== item?.id) && !isMD ?
                                    <AddIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(state => state === item?.id ? 0 : item?.id)}} />
                                : null}
                            </div>
                            {sublist.map((sub, subIndex) => {
                                const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                                return (
                                    <div key={`topic-item-mobile-${subIndex}`} onClick={() => window.open(isUrl ? sub['url'] : "#", isUrl ? "_blank" : "_self")} className={`${isMD ? "flex" : (subActiveMenuID === item?.id) && !isMD ? "flex" : "hidden"} px-0 md:px-2 py-1 items-start text-xl sm:text-2xl bg-white border-menu border-opacity-30 hover:bg-adminBtn group hover:text-white cursor-pointer`}>
                                        <div className='rounded-full bg-adminBtn group-hover:bg-white mt-3.5 ml-6' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                        <span className='pt-1 pl-2'>
                                            {sub['title']}
                                        </span>
                                    </div>
                                )
                            })}
                            <div className='border-b-2 border-adminBtn pt-2 md:w-11/12 mx-auto'></div>
                        </Grid>    
                    )
                }) : (
                    <Grid item xs={12}>
                        <div className={`flex px-2 py-1 items-center text-2xl bg-white border-menu border-opacity-30 hover:bg-adminBtn group hover:text-white`}>
                            <div className='rounded-full bg-adminBtn group-hover:bg-white mt-1 ml-6' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                            <span className='pt-1 pl-2'>
                                ไม่มีข้อมูล
                            </span>
                        </div>
                    </Grid>
                )}
            </Grid>
            <div className='w-full max-w-lg mx-auto py-4'>
                <img alt="topic-banner" src={BannerTopic} />
            </div>
        </div>
    );
}

export default TopicPage;