import { createSlice } from '@reduxjs/toolkit';

const initialState = { value: {
    token: null,
    displayName: null,
    email: null,
    firstName: null,
    lastName: null,
    title: null,
    deptId: null,
    telNo: null,
    acceptPDPA: null,
    newsSubscribe: null,
    isAdmin: null
} };

export const authUserSlice = createSlice({
    name: 'authUser',
    initialState,
    reducers: {
        setAuthUser: (state, action) => {
            if(!state.value?.token){
                state.value = {token: localStorage.getItem("wktefy"), displayName: localStorage.getItem("displayName"), ...action.payload};
            } else {
                state.value = {...state.value, ...action.payload};
            }
        },
        clearAuthUser: (state) => {
            state.value = initialState.value;
            localStorage.clear();
        }
    },
})

export const { setAuthUser, clearAuthUser } = authUserSlice.actions;
export default authUserSlice.reducer;