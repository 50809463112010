import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, Modal, Paper, IconButton, TextField, Button, FormControl, MenuItem, Select, InputAdornment } from '@mui/material';

import Validator from '../utils/Validator';
import Logo from '../assets/images/HD.png';
import PassIcon from '../assets/images/key.png';
import EditIcon from '../assets/images/edit.png';
import { openSnackbar } from '../reducers/notify';
import EmailIcon from '../assets/images/mail.png';
import AdminIcon from '../assets/images/admin.png';
import ForgotIcon from '../assets/images/forgot.png';
import ProfileIcon from '../assets/images/profile.png';
import { useLoginMutation, useForgotPasswordMutation, useGetDepartmentQuery, useRegisterMutation } from '../services/main';

const ModalLogin = ({open = false, handleClose = () => {}}) => {
    const dispatch = useDispatch();
    const [activeType, setActiveType] = useState("login");
    const [login] = useLoginMutation();
    const [register] = useRegisterMutation();
    const [forgotPassword] = useForgotPasswordMutation();
    const { data: departmentData } = useGetDepartmentQuery();
    const departmentList = departmentData?.departments || [];
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [orderName, setOrderName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [department, setDepartment] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);

    const onModalClose = () => {
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setOrderName("");
        setFirstName("");
        setLastName("");
        setPhoneNumber("");
        setDepartment("");

        if(activeType !== 'login'){
            setActiveType("login");
        }else{
            handleClose();
        }
    }

    const handleOnlogin = (e) => {
        e.preventDefault();
        if(!email || !password){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
            return;
        }else if(!Validator.validateData(email, 'email').isValid){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }

        login({userName: email, password}).then(res => {
            const { data } = res;
            if(data?.success){
                onModalClose();
            }
        }).catch((error) => {
            dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
        });
    };

    const handleOnForgotPass = (e) => {
        e.preventDefault();
        if(!email){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
            return;
        }else if(!Validator.validateData(email, 'email').isValid){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }

        forgotPassword({email}).then(res => {
            const { data } = res;
            if(data?.success){
                onModalClose();
                dispatch(openSnackbar({ options: { variant: "success" }, message: data?.message || "ส่งอีเมลสำเร็จ กรุณาตรวจสอบอีเมลของท่าน เพื่อตั้งรหัสผ่านใหม่ภายใน 15 นาที" }))
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: data?.message || "พบปัญหาในการส่งอีเมลตั้งรหัสผ่าน กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ" }))
            }
        }).catch((error) => {
            dispatch(openSnackbar({ options: { variant: "error" }, message: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง" }))
        });
    }

    const handleOnRegister = (e) => {
        e.preventDefault();
        if(!email){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }else if(!Validator.validateData(email, 'email').isValid){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }else if(password !== confirmPassword){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกรหัสผ่านให้ตรงกัน" }))
            return;
        }else if(!orderName || !firstName || !lastName || !phoneNumber || !password || !confirmPassword){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกข้อมูลให้ครบถ้วน" }))
            return;
        }else if(!department && (department !== 0)){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกข้อมูลให้ครบถ้วน" }))
            return;
        }

        const genData = {
            firstName: firstName,
            lastName: lastName,
            title: orderName,
            deptId: department,
            email: email,
            telNo: phoneNumber,
            password: password
        };

        register(genData).then(res => {
            const { data } = res;
            if(data?.success){
                onModalClose();
                dispatch(openSnackbar({ options: { variant: "success" }, message: data?.message || "บันทึกข้อมูลการลงทะเบียนสำเร็จ จะสามารถเข้าใช้งานระบบได้เมื่อได้รับอีเมลแจ้งจากเจ้าหน้าที่ดูแลระบบ" }))
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: data?.message || "พบปัญหาในการสมัครสมาชิก กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ" }))
            }
        }).catch((error) => {
            dispatch(openSnackbar({ options: { variant: "error" }, message: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง" }))
        });
    }

    return (
        <Modal open={open} onClose={onModalClose} aria-labelledby="modal-login-title" aria-describedby="modal-login-description" className="flex justify-center items-center">
            <Paper elevation={0} style={{maxWidth: '90%', width: 600, outline: 'none', overflowY:'auto', maxHeight:'90vh', borderRadius: '1.5rem'}}>
                <div className='flex items-center text-white bg-adminBtn pt-8 px-8'>
                    <div className='flex-grow font-bold text-4xl'>
                        <span className='inline-block align-bottom'>{activeType === 'register' ? "ลงทะเบียน" : activeType === 'forgotPassword' ? "ลืมรหัสผ่าน" : 'เข้าสู่ระบบ'}</span>
                    </div> 
                    <div>
                        <IconButton aria-label="close" onClick={onModalClose} sx={{margin: "-8px"}} className="w-8 h-8 my-auto text-white">
                            <CloseIcon fontSize='large' className='text-white' />
                        </IconButton>
                    </div>
                </div>
                <div className='flex-grow flex justify-center items-center flex-row bg-adminBtn px-8 pb-8 pt-4'>
                    <img src={Logo} />
                    <div className='flex flex-col text-white font-bold text-2xl leading-5 pl-2'>
                        <span>กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม</span>  
                        <span>Ministry of Natural Resources and Environment</span>  
                    </div>
                </div>
                {activeType === 'register' ? 
                    <form autoComplete="off" onSubmit={handleOnRegister}>
                        <Grid container className='py-8 px-16'>
                            <Grid item xs={12} className="flex items-center">
                                <img src={EmailIcon} className="w-10 h-10 mr-4" />
                                <TextField required id="regis-email" type={"email"} label="" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" fullWidth placeholder='อีเมล' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </Grid>
                            <Grid item xs={12} className="flex items-center pt-4">
                                <img src={PassIcon} className="w-10 h-10 mr-4" />
                                <TextField required id="regis-pass" type={isShowPassword ? "text" : "password"} label="" value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" fullWidth placeholder='รหัสผ่าน' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={()=>setIsShowPassword(state => !state)} onMouseDown={e=>e.preventDefault()} edge="end">
                                                    {isShowPassword ? <VisibilityIcon className='text-adminBtn' /> : <VisibilityOffIcon className='text-adminBtn' />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="flex items-center pt-4 pb-10">
                                <img src={PassIcon} className="w-10 h-10 mr-4" />
                                <TextField required id="regis-confirm" type={isShowPassword ? "text" : "password"} label="" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} variant="outlined" fullWidth placeholder='ยืนยันรหัสผ่าน' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </Grid>
                            <Grid item xs={12} className="flex items-center">
                                <div className='w-full border-l-2 pl-8 ml-5'>
                                    <TextField required id="regis-name-order" label="" value={orderName} onChange={(e) => setOrderName(e.target.value)} variant="outlined" fullWidth placeholder='คำนำหน้าชื่อ' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} className="flex items-center">
                                <div className='w-full border-l-2 pl-8 ml-5 pt-4'>
                                    <TextField required id="regis-first-name" label="" value={firstName} onChange={(e) => setFirstName(e.target.value)} variant="outlined" fullWidth placeholder='ชื่อ' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} className="flex items-center py-4">
                                <img src={ProfileIcon} className="w-10 h-10 mr-4" />
                                <TextField required id="regis-last-name" label="" value={lastName} onChange={(e) => setLastName(e.target.value)} variant="outlined" fullWidth placeholder='นามสกุล' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </Grid>
                            <Grid item xs={12} className="flex items-center">
                                <div className='w-full border-l-2 pl-8 ml-5'>
                                    <TextField required id="regis-phone" label="" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} variant="outlined" fullWidth placeholder='หมายเลขโทรศัพท์' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} className="flex items-center">
                                <div className='w-full border-l-2 pl-8 ml-5 pt-4'>
                                    <span className='text-2xl font-bold'>หน่วยงาน</span>
                                    <FormControl fullWidth >
                                        <Select id="department-small" label="" value={department} onChange={(e) => setDepartment(e.target.value)} className='bg-white' sx={{ color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', verticalAlign: 'bottom', 'div' : { py: 0 }, 'input::placeholder': { opacity: 0.8 } }}>
                                            {departmentList?.map((item, index) => {
                                                return(
                                                    <MenuItem key={`department-option-${index}`} value={item['id']}>
                                                        {item['title']}
                                                    </MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                            <Grid item xs={12} className="flex items-center pt-10 pb-8">
                                <Button type='sumbit' variant='contained' fullWidth sx={{ backgroundColor: 'rgba(109,124,86,1)', fontSize: '1.8rem', fontWeight: 'bold', borderRadius: '1.3rem' }}>
                                    ลงทะเบียน
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                : activeType === 'forgotPassword' ?
                    <form autoComplete="off" onSubmit={handleOnForgotPass}>
                        <Grid container className='py-8 px-16'>
                            <Grid item xs={12} className="flex items-center">
                                <img src={EmailIcon} className="w-12 h-12 mr-4" />
                                <TextField required id="forgetpass-email" type={"email"} label="" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" fullWidth placeholder='อีเมล' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </Grid>
                            <Grid item xs={12} className="flex items-center text-2xl font-bold pt-4 text-mainBtn">
                                ระบบจะทำการส่งรหัสผ่านของคุณไปให้ที่อีเมลนี้ กรุณากรอกให้ถูกต้องและสมบูรณ์
                            </Grid>
                            <Grid item xs={12} className="flex items-center pt-8 pb-2">
                                <Button type='sumbit' variant='contained' fullWidth sx={{ backgroundColor: 'rgba(109,124,86,1)', fontSize: '1.8rem', fontWeight: 'bold', borderRadius: '1.3rem' }}>
                                    ยืนยัน
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                :
                    <form autoComplete="off" onSubmit={handleOnlogin}>
                        <Grid container className='py-8 px-16'>
                            <Grid item xs={12} className="flex items-center">
                                <img src={EmailIcon} className="w-12 h-12 mr-4" />
                                <TextField required id="email" type={"email"} label="" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" fullWidth placeholder='อีเมล' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </Grid>
                            <Grid item xs={12} className="flex items-center pt-8">
                                <img src={PassIcon} className="w-12 h-12 mr-4" />
                                <TextField required id="pass" type={isShowPassword ? "text" : "password"} label="" value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" fullWidth placeholder='รหัสผ่าน' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label="toggle password visibility" onClick={()=>setIsShowPassword(state => !state)} onMouseDown={e=>e.preventDefault()} edge="end">
                                                    {isShowPassword ? <VisibilityIcon className='text-adminBtn' /> : <VisibilityOffIcon className='text-adminBtn' />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />  
                            </Grid>
                            <Grid item xs={12} className="flex items-center pt-8 pb-16">
                                <Button type='sumbit' variant='contained' fullWidth sx={{ backgroundColor: 'rgba(109,124,86,1)', fontSize: '1.8rem', fontWeight: 'bold', borderRadius: '1.3rem' }}>
                                    เข้าสู่ระบบ
                                </Button>
                            </Grid>
                            <Grid item xs={4} className="cursor-pointer" onClick={() => setActiveType("register")}>
                                <div className='flex justify-center'>
                                    <img src={EditIcon} className="w-10" />
                                </div>
                                <div className='flex justify-center'>
                                    <span className='text-2xl font-bold pt-2 text-menu'>
                                        ลงทะเบียน
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="cursor-pointer" onClick={() => setActiveType("forgotPassword")}>
                                <div className='flex justify-center'>
                                    <img src={ForgotIcon} className="w-10" />
                                </div>
                                <div className='flex justify-center'>
                                    <span className='text-2xl font-bold pt-2 text-menu'>
                                        ลืมรหัสผ่าน
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={4} className="cursor-pointer" onClick={() => window.open("https://adfs.mnre.go.th/adfs/ls/idpinitiatedsignon.aspx", "_blank")}>
                                <div className='flex justify-center'>
                                    <img src={AdminIcon} className="w-10" />
                                </div>
                                <div className='flex justify-center'>
                                    <span className='text-2xl font-bold pt-2 text-menu'>
                                        สำหรับผู้ดูแลระบบ
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                }
            </Paper>
        </Modal>
    )
}

export default ModalLogin;