import React from 'react';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const BackDrop = () => {
    const loading = useSelector((state) => state.loading.value);

    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 101 }} open={loading}>
            <Grid container>
                <Grid item xs={12} className="flex justify-center">
                    <CircularProgress color="primary" thickness={5} size="4rem" />
                </Grid>
                <Grid item xs={12} className="flex justify-center">
                    <span className="mt-4 font-semibold">กำลังโหลด.....</span>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default BackDrop;