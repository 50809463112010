import CloseIcon from '@mui/icons-material/Close';
import { Modal, Paper, IconButton, Divider, Checkbox, FormControlLabel, FormGroup, Button } from '@mui/material';

import { useLogoutMutation, useSavePDPAMutation } from '../services/main';

const ModalPDPA = ({open = false, handleClose = () => {}}) => {
    const [logout] = useLogoutMutation();
    const [savePDPA] = useSavePDPAMutation();

    const handleLogout = () => {
        logout()
        handleClose()
    }
    
    const handleSubmit = () => {
        savePDPA();
        handleClose();
    }

    return (
        <Modal open={open} aria-labelledby="modal-login-title" aria-describedby="modal-login-description" className="flex justify-center items-center" disableEscapeKeyDown={true}>
            <Paper elevation={0} style={{maxWidth: '90%', width: 600, outline: 'none', overflowY:'auto', maxHeight:'90vh', borderRadius: '1.5rem'}}>
                <div className='flex items-center pt-6 pb-2 px-8'>
                    <div className='flex-grow font-bold text-2xl'>
                        <span className='inline-block align-bottom'>{'ข้อตกลงและเงื่อนไข'}</span>
                    </div> 
                    <div>
                        <IconButton aria-label="close" onClick={handleLogout} sx={{margin: "-8px"}} className="w-8 h-8 my-auto">
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div className='px-8'>
                    <Divider />
                </div>
                <div className='pt-3 px-8 text-xl font-bold'>
                    1. ข้อมูลในเว็บไซต์ไม่สามารถนำไปใช้อ้างอิงทางกฎหมายได้
                </div>
                <div className='pt-2 px-8 text-xl font-bold'>
                    2. ห้ามนำข้อมูลไปใช้ประโยชน์เชิงพาณิชย์
                </div>
                <div className='pt-2 px-8 text-xl font-bold'>
                    3. ข้าพเจ้ายอมรับ<a href='http://www.mnre.go.th/th/policy/content/1049' target="_blank" className='underline'>นโยบาย</a>คุ้มครองข้อมูลส่วนบุคคลของกระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม
                </div>
                <div className='mt-4 mx-8 border-2 border-adminBtn flex justify-center'>
                    <FormGroup className='px-2'>
                        <FormControlLabel control={<Checkbox checked={true} color="adminBtn" />} label="ข้าพเจ้ายอมรับข้อตกลงและเงื่อนไข" sx={{ 'span': { fontSize: '1.25rem', fontWeight: 'bold' } }} />
                    </FormGroup>
                </div>
                <div className='mt-3 mx-8 mb-8 flex justify-center'>
                    <Button variant='contained' fullWidth sx={{ fontSize: '1.25rem', fontWeight: 'bold' }} onClick={handleSubmit}>
                        ยอมรับ
                    </Button>
                </div>
            </Paper>
        </Modal>
    )
}

export default ModalPDPA;
