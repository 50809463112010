import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, CssBaseline, useMediaQuery } from '@mui/material';

import Header from '../components/header';
import Footer from '../components/footer';
import HeaderMobile from '../components/headerMobile';
import { useGetAuthUserQuery } from '../services/main';

const BASE_NAME = process.env.REACT_APP_BASE_NAME;

export default function HomeLayout({children}) {
    const location = useLocation();
    const isAboutPage = window.location.pathname === (BASE_NAME+'/about');
    const isLarge = useMediaQuery(theme=>theme.breakpoints.up('md'));
    const { data: authUser, refetch: authRefetch, error: authUserError, isLoading: authLoading } = useGetAuthUserQuery();
    
    useEffect(() => {
        if(authUser){
            authRefetch();
        }
    }, [location]);

    return (
        <Box className="flex flex-col" >
            <CssBaseline />
            {isLarge ? 
                <Header />
            : <HeaderMobile />}
            <Box component="main" className='bg-white' sx={{ flexGrow: 1, p: !isLarge && isAboutPage ? 0 : 3 }}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
}
