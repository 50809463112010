import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import authReducer from '../reducers/authUser';
import notifyReducer from '../reducers/notify';
import loadingReducer from '../reducers/loading';
import { mainService } from '../services/main';

export const store = configureStore({
    reducer: {
        authUser: authReducer,
        notify: notifyReducer,
        loading: loadingReducer,
        [mainService.reducerPath]: mainService.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([mainService.middleware]),
})

setupListeners(store.dispatch)