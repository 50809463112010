import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Modal, Paper, IconButton } from '@mui/material';

import GisIcon from '../assets/images/gis.png';
import CMSIcon from '../assets/images/cms.png';
import BgLogin from '../assets/images/bg-login.png'
import TableauIcon from '../assets/images/tableau.png';
import DataIcon from '../assets/images/data-platform.png';

const ModalSystemManagement = ({open = false, handleClose = () => {}}) => {
    const authUser = useSelector(state=>state.authUser.value);
    const onModalClose = () => {
        handleClose();
    }

    return (
        <Modal open={open} onClose={onModalClose} aria-labelledby="modal-login-title" aria-describedby="modal-login-description" className="flex justify-center items-center">
            <Paper elevation={0} style={{maxWidth: '90%', width: 600, outline: 'none', overflowY:'auto', maxHeight:'90vh', borderRadius: '1.5rem'}}>
                <div className='flex items-center text-white py-6 px-8' style={{ background: `linear-gradient(105.15deg, rgb(17, 153, 142) 22.48%, rgb(3, 193, 75) 131%) 0% 0% / cover, url(${BgLogin})` }}>
                    <div className='flex-grow font-bold text-2xl'>
                        <span className='inline-block align-bottom'>{'System management'}</span>
                    </div> 
                    <div>
                        <IconButton aria-label="close" onClick={onModalClose} sx={{margin: "-8px"}} className="w-8 h-8 my-auto text-white">
                            <CloseIcon className='text-white' />
                        </IconButton>
                    </div>
                </div>
                <Grid container>
                    <Grid item xs={6} className="flex justify-center">
                        <img src={CMSIcon} alt="admin-CMSIcon" style={{width: '80%'}} className="cursor-pointer" onClick={() => window.open(`http://naturebi.mnre.go.th:4000?cms_token=${authUser.token}`, "_blank")} />
                    </Grid>
                    <Grid item xs={6} className="flex justify-center">
                        <img src={DataIcon} alt="admin-DataIcon" style={{width: '80%'}} className="cursor-pointer" onClick={() => window.open(`http://sdp1.mnre.go.th:3030?cms_token=${authUser.token}`, "_blank")} />
                    </Grid>
                    <Grid item xs={6} className="flex justify-center">
                        <img src={GisIcon} alt="admin-GisIcon" style={{width: '80%'}} className="cursor-pointer" onClick={() => window.open(`https://gis.mnre.go.th?cms_token=${authUser.token}`, "_blank")} />
                    </Grid>
                    <Grid item xs={6} className="flex justify-center">
                        <img src={TableauIcon} alt="admin-TableauIcon" style={{width: '80%'}} className="cursor-pointer" onClick={() => window.open(`https://tableau.mnre.go.th/?cms_token=${authUser.token}`, "_blank")} />
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default ModalSystemManagement;