import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Grid, TextField, Button, FormControl, MenuItem, Select, FormGroup, FormControlLabel, Checkbox, CircularProgress, InputAdornment, IconButton } from '@mui/material';

import Validator from '../utils/Validator';
import PassIcon from '../assets/images/key.png';
import { openSnackbar } from '../reducers/notify';
import EmailIcon from '../assets/images/mail.png';
import ProfileIcon from '../assets/images/profile.png';
import { useUpdatePasswordMutation, useGetDepartmentQuery, useUpdateProfileMutation, useForceUpdatePasswordMutation } from '../services/main';

const ProfilePage = () => {
    const [updateProfile] = useUpdateProfileMutation();
    const [updatePassword] = useUpdatePasswordMutation();
    const [forceUpdatePassword] = useForceUpdatePasswordMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const authUser = useSelector(state => state.authUser.value);
    const dispatch = useDispatch();
    const [queryParameters] = useSearchParams();
    const queryToken = queryParameters.get("token");
    const isForceResetPassword = (location?.pathname === '/reset-password') && queryToken
    const { data: departmentData } = useGetDepartmentQuery();
    const departmentList = departmentData?.departments || [];

    const [type, setType] = useState(isForceResetPassword ? "password" : "profile");
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [orderName, setOrderName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [department, setDepartment] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [acceptPDPA, setAcceptPDPA] = useState(false);
    const [newsSubscribe, setNewsSubscribe] = useState(false);
    const [password, setPassword] = useState("");
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");

    useEffect(() => {
        if(isForceResetPassword){
            return;
        }

        if(!authUser.token || !authUser.email){
            const timeOutID = setTimeout(() => {
                navigate("/")
            }, 1000);
            return () => clearTimeout(timeOutID);
        }

        setEmail(authUser?.email || "")
        setLastName(authUser?.lastName || "")
        setOrderName(authUser?.title || "")
        setFirstName(authUser?.firstName || "")
        setDepartment(authUser?.deptId)
        setPhoneNumber(authUser?.telNo || "")
        setAcceptPDPA(authUser?.acceptPDPA || false)
        setNewsSubscribe(authUser?.newsSubscribe || false)
    }, [authUser])
    

    const handleOnRegister = (e) => {
        e.preventDefault();
        if(!email){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }else if(!Validator.validateData(email, 'email').isValid){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }else if(!orderName || !firstName || !lastName || !phoneNumber){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกข้อมูลให้ครบถ้วน" }))
            return;
        }else if(!department && (department !== 0)){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกข้อมูลให้ครบถ้วน" }))
            return;
        }

        const genData = {
            firstName: firstName,
            lastName: lastName,
            title: orderName,
            deptId: department,
            email: email,
            telNo: phoneNumber,
            acceptPDPA: acceptPDPA,
            newsSubscribe: newsSubscribe
        };

        updateProfile(genData).then(res => {
            const { data } = res;
            if(data?.success){
                dispatch(openSnackbar({ options: { variant: "success" }, message: data?.message || "แก้ไขข้อมูลสำเร็จ" }))
            }else{
                dispatch(openSnackbar({ options: { variant: "error" }, message: data?.message || "พบปัญหาในการแก้ไขข้อมูล กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ" }))
            }
        }).catch((error) => {
            dispatch(openSnackbar({ options: { variant: "error" }, message: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง" }))
        });
    }

    const handleOnPasswordChange = (e) => {
        e.preventDefault();
        if(!password || !confirmPassword){
            return;
        }else if(password !== confirmPassword){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกรหัสผ่านให้ตรงกัน" }))
            return;
        }

        const genData = {
            password: password
        };

        if(isForceResetPassword){
            forceUpdatePassword({header: `Bearer ${queryToken}`, data: genData}).then(res => { 
                const { data } = res;
                if(data?.success){
                    navigate("/")
                    dispatch(openSnackbar({ options: { variant: "success" }, message: data?.message || "แก้ไขข้อมูลสำเร็จ" }))
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: data?.message || "พบปัญหาในการแก้ไขข้อมูล กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ" }))
                }
            }).catch((error) => {
                dispatch(openSnackbar({ options: { variant: "error" }, message: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง" }))
            });
        }else{
            updatePassword(genData).then(res => {
                const { data } = res;
                if(data?.success){
                    setType("profile")
                    dispatch(openSnackbar({ options: { variant: "success" }, message: data?.message || "แก้ไขข้อมูลสำเร็จ" }))
                }else{
                    dispatch(openSnackbar({ options: { variant: "error" }, message: data?.message || "พบปัญหาในการแก้ไขข้อมูล กรุณาติดต่อเจ้าหน้าที่ผู้ดูแลระบบ" }))
                }
            }).catch((error) => {
                dispatch(openSnackbar({ options: { variant: "error" }, message: "มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง" }))
            });
        }
    }

    return (authUser?.token && authUser?.email) || isForceResetPassword ? (
        <div className="max-w-xl mx-auto pt-6">
           <div className='flex text-4xl'>
                <div className="flex">
                    <div className={'font-bold text-1line'}>
                        {type === 'password' ? "เปลี่ยนรหัสผ่าน" : "โปรไฟล์"}
                    </div>
                </div>
            </div>
            <div className="border-t-2 border-dashed mt-2 mb-8" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
            {type === 'password' ?
                <form autoComplete="off" onSubmit={handleOnPasswordChange}>
                    <Grid container className='max-w-md mx-auto'>
                        <Grid item xs={12} className="flex items-center pt-4">
                            <img src={PassIcon} className="w-10 h-10 mr-4" />
                            <TextField required id="regis-pass" type={isShowPassword ? "text" : "password"} label="" value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" fullWidth placeholder='รหัสผ่าน' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={()=>setIsShowPassword(!isShowPassword)} onMouseDown={e=>e.preventDefault()} edge="end">
                                                {isShowPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className="flex items-center pt-4 pb-10">
                            <img src={PassIcon} className="w-10 h-10 mr-4" />
                            <TextField required id="regis-confirm" type={isShowPassword ? "text" : "password"} label="" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} variant="outlined" fullWidth placeholder='ยืนยันรหัสผ่าน' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                        </Grid>
                        <Grid item xs={12} className="flex items-center pt-10 pb-8">
                            <Button type='sumbit' variant='contained' fullWidth sx={{ backgroundColor: 'rgba(109,124,86,1)', fontSize: '1.8rem', fontWeight: 'bold', borderRadius: '1.3rem' }}>
                                บันทึก
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            :
                <form autoComplete="off" onSubmit={handleOnRegister}>
                    <Grid container className='max-w-md mx-auto'>
                        <Grid item xs={12} className="flex items-center pb-10">
                            <img src={EmailIcon} className="w-10 h-10 mr-4" />
                            <TextField required id="regis-email" type={"email"} label="" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" fullWidth placeholder='อีเมล' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                        </Grid>
                        <Grid item xs={12} className="flex items-center">
                            <div className='w-full border-l-2 pl-8 ml-5'>
                                <TextField required id="regis-name-order" label="" value={orderName} onChange={(e) => setOrderName(e.target.value)} variant="outlined" fullWidth placeholder='คำนำหน้าชื่อ' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} className="flex items-center">
                            <div className='w-full border-l-2 pl-8 ml-5 pt-4'>
                                <TextField required id="regis-first-name" label="" value={firstName} onChange={(e) => setFirstName(e.target.value)} variant="outlined" fullWidth placeholder='ชื่อ' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} className="flex items-center py-4">
                            <img src={ProfileIcon} className="w-10 h-10 mr-4" />
                            <TextField required id="regis-last-name" label="" value={lastName} onChange={(e) => setLastName(e.target.value)} variant="outlined" fullWidth placeholder='นามสกุล' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                        </Grid>
                        <Grid item xs={12} className="flex items-center">
                            <div className='w-full border-l-2 pl-8 ml-5'>
                                <TextField required id="regis-phone" label="" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} variant="outlined" fullWidth placeholder='หมายเลขโทรศัพท์' sx={{ 'input': { color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', py: 0, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8 } }} />
                            </div>
                        </Grid>
                        <Grid item xs={12} className="flex items-center">
                            <div className='w-full border-l-2 pl-8 ml-5 pt-4'>
                                <span className='text-2xl font-bold'>หน่วยงาน</span>
                                <FormControl fullWidth >
                                    <Select id="department-small" required label="" value={department} onChange={(e) => setDepartment(e.target.value)} className='bg-white' sx={{ color: 'rgba(155,177,122,1)', fontSize: '1.8rem', fontWeight: 'bold', verticalAlign: 'bottom', 'div' : { py: 0 }, 'input::placeholder': { opacity: 0.8 } }}>
                                        {departmentList?.map((item, index) => {
                                            return(
                                                <MenuItem key={`department-option-${index}`} value={item['id']}>
                                                    {item['title']}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} className="flex items-center pt-6">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={acceptPDPA} onChange={(e)=>setAcceptPDPA(e.target.checked)} />} label="ยอมรับ PDPA" sx={{ 'span': { fontSize: '1.8rem', fontWeight: 'bold', color: 'rgba(155,177,122,1)' }, '& .MuiSvgIcon-root': { fontSize: '2rem', color: 'rgba(155,177,122,1)' } }} />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} className="flex items-center pt-4">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={newsSubscribe} onChange={(e)=>setNewsSubscribe(e.target.checked)} />} label="ยินดีรับข่าวประชาสัมพันธ์" sx={{ 'span': { fontSize: '1.8rem', fontWeight: 'bold', color: 'rgba(155,177,122,1)' }, '& .MuiSvgIcon-root': { fontSize: '2rem', color: 'rgba(155,177,122,1)' } }} />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={12} className="flex items-center pt-4 cursor-pointer" onClick={()=>setType("password")}>
                            <img src={PassIcon} className="w-10 h-10" />
                            <span className='pl-2' style={{fontSize: '1.8rem', fontWeight: 'bold', color: 'rgba(155,177,122,1)'}}>
                                คลิกที่นี่เพื่อเปลี่ยนรหัสผ่าน
                            </span>
                        </Grid>
                        <Grid item xs={12} className="flex items-center pt-10 pb-8">
                            <Button type='sumbit' variant='contained' fullWidth sx={{ backgroundColor: 'rgba(109,124,86,1)', fontSize: '1.8rem', fontWeight: 'bold', borderRadius: '1.3rem' }}>
                                บันทึก
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            }
        </div>
    ) : (
        <div className="max-w-xl mx-auto pt-6">
            <div className='flex text-4xl'>
                <div className="flex">
                    <div className={'font-bold text-1line'}>
                        {type === 'password' ? "เปลี่ยนรหัสผ่าน" : "โปรไฟล์"}
                    </div>
                </div>
            </div>
            <div className="border-t-2 border-dashed mt-2 mb-8" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
            <div className='w-full flex items-center justify-center py-36'>
                <CircularProgress size={60} thickness={8} />
            </div>
        </div>  
    )
}

export default ProfilePage;
