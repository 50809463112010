import { useSelector } from 'react-redux';
import { Grid, useMediaQuery } from '@mui/material';

import ImgHighlight from "../assets/images/highlightMain.png";
import { useGetPublicTopicQuery, useGetPrivateTopicQuery } from '../services/main';

const HighlightPage = () => {
    const authUser = useSelector(state=>state.authUser.value);
    const isMD = useMediaQuery(theme=>theme.breakpoints.up('md'));
    const { data: publicTopicData } = useGetPublicTopicQuery(null, { skip: authUser?.token });
    const { data: privateTopicData } = useGetPrivateTopicQuery(null, { skip: !authUser?.token });
    const highlightList = privateTopicData?.highlights || publicTopicData?.highlights || [];

    return (
        <div className="max-w-xl mx-auto pt-6">
            <div className="md:border-l-8 border-adminBtn text-4xl md:text-6xl font-bold pl-2 mb-4" style={{lineHeight: isMD ? '3.2rem' : "2rem"}}>
                รายงานเด่นด้านทรัพยากรธรรมชาติ<br />
                และสิ่งแวดล้อม
            </div>
            <Grid container>
                <Grid item xs={12} className="md:hidden pb-4">
                    <div className="border-t-2 border-dashed" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
                </Grid>
                <Grid item xs={12} md={5}>
                    {highlightList && highlightList.length ? highlightList?.map((item, itemIndex) => {
                        const sublist = item?.dashboards || [];
                        return sublist.map((sub, subIndex) => {
                            const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                            return (
                                <div key={`highlight-item-mobile-${subIndex}`} onClick={() => window.open(isUrl ? sub['url'] : "#", isUrl ? "_blank" : "_self")} className={`flex px-0 md:px-2 py-1 items-center text-xl sm:text-2xl bg-white border-menu border-opacity-30 hover:bg-adminBtn group hover:text-white cursor-pointer`}>
                                    <div className='rounded-full bg-adminBtn group-hover:bg-white mt-1 ml-2' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                    <span className='pt-1 pl-2'>
                                        {sub['title']}
                                    </span>
                                </div>
                            )
                        })
                    }) : (
                        <div className={`flex px-2 py-1 items-center text-2xl bg-white border-menu border-opacity-30 hover:bg-adminBtn group hover:text-white`}>
                            <div className='rounded-full bg-adminBtn group-hover:bg-white mt-1 ml-2' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                            <span className='pt-1 pl-2'>
                                ไม่มีข้อมูล
                            </span>
                        </div>
                    )}
                </Grid>
                <Grid item xs={12} className="md:hidden py-4">
                    <div className="border-t-2 border-dashed" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
                </Grid>
                <Grid item xs={12} md={7} className="flex items-end justify-center">
                    <img alt='img-highlight' src={ImgHighlight} />
                </Grid>
            </Grid>      
        </div>
    );
}

export default HighlightPage;