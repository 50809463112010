import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import RemoveIcon from '@mui/icons-material/Remove';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import { AppBar, Box, Toolbar, IconButton, TextField, Grid, Button } from '@mui/material';

import ModalPDPA from './moadalPDPA';
import Logo from '../assets/images/HD.png';
import Validator from '../utils/Validator';
import ImgCloud from '../assets/images/cloud.png';
import { openSnackbar } from '../reducers/notify';
import HomeIconInactive from '../assets/images/Image_24.png';
import TopicIconInactive from '../assets/images/Image_18.png';
import OtherIconInactive from '../assets/images/Image_19.png';
import HilightIconInactive from '../assets/images/Image_17.png';
import IconServiceInactive from '../assets/images/Image_62.png';
import BannerMain from '../assets/images/village-in-the-forest-with-sun.png';
import { useGetPublicMenuQuery, useGetMenuQuery, useGetPublicTopicQuery, useGetPrivateTopicQuery, useLoginMutation, useLogoutMutation } from '../services/main';

const BASE_NAME = process.env.REACT_APP_BASE_NAME;

const HeaderMobile = () => {
    const authUser = useSelector(state => state.authUser.value);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [login] = useLoginMutation();
    const [logout] = useLogoutMutation();
    const isAboutPage = window.location.pathname === (BASE_NAME+'/about');
    const isNewsPage = window.location.pathname === (BASE_NAME+'/news');
    const { data: privateMenuData } = useGetMenuQuery(null, { skip: !authUser?.token });
    const { data: publicMenuData } = useGetPublicMenuQuery(null, { skip: authUser?.token });
    const { data: publicTopicData } = useGetPublicTopicQuery(null, { skip: authUser?.token });
    const { data: privateTopicData } = useGetPrivateTopicQuery(null, { skip: !authUser?.token });

    const menuData = privateMenuData || publicMenuData;
    const menuList = menuData?.main || [];
    const topicList = privateTopicData?.topics || publicTopicData?.topics || [];
    const highlightList = privateTopicData?.highlights || publicTopicData?.highlights || [];
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [modalPDPAOpen, setModalPDPAOpen] = useState(false);
    const [subActiveMenuID, setSubActiveMenuID] = useState(0);
    const [topicOpen, setTopicOpen] = useState(false);
    const [serviceOpen, setServiceOpen] = useState(false);
    const [highlightOpen, setHighlightOpen] = useState(false);

    useEffect(() => {
        if(authUser?.token && authUser?.email && !authUser?.acceptPDPA){
            setModalPDPAOpen(true)
        }
    }, [authUser])

    const handleOnClearInput = () => {
        setEmail("");
        setPassword("");
    }

    const handleMenuClick = (menuTitile, link) => {
        setIsMenuOpen(false)
        setSubActiveMenuID(0)
        navigate(link)
    }

    const openUrl = (url) => {
        setIsMenuOpen(false)
        setSubActiveMenuID(0)
        window.open(url, "_blank")
    }

    const clearSubMenu = () => {
        handleCloseTopic();
        handleCloseService();
        handleCloseHighlight();
    }

    const handleCloseHighlight = () => {
        if(highlightOpen){
            setHighlightOpen(false);
        }
    };

    const handleCloseTopic = () => {
        if(topicOpen){
            setTopicOpen(false);
        }
    };

    const handleCloseService = () => {
        if(serviceOpen){
            setServiceOpen(false);
        }
    };

    const handleOnlogin = (e) => {
        e.preventDefault();
        if(!email || !password){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
            return;
        }else if(!Validator.validateData(email, 'email').isValid){
            dispatch(openSnackbar({ options: { variant: "error" }, message: "กรุณากรอกอีเมลให้ถูกต้อง" }))
            return;
        }

        login({userName: email, password}).then(res => {
            const { data } = res;
            if(data?.success){
                setSubActiveMenuID(0)
                setIsMenuOpen(false)
                handleOnClearInput();
            }
        }).catch((error) => {
            dispatch(openSnackbar({ options: { variant: "error" }, message: "อีเมลหรือรหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง" }))
        });
    };

    const handleLogout = () => {
        setSubActiveMenuID(0)
        setIsMenuOpen(false)
        handleOnClearInput()
        logout()
    }

    return (
        <div style={{zIndex: 999}}>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar>
                        <img alt='img-header-mobile-logo' src={Logo} style={{width: 40, marginRight: '8px'}} />
                        <div className='flex flex-col text-white font-bold text-base sm:text-lg leading-4 sm:leading-4 flex-grow text-1line'>
                            <span>กระทรวงทรัพยากรธรรมชาติและสิ่งแวดล้อม</span>  
                            <span className='font-normal'>Ministry of Natural Resources and Environment</span>  
                        </div>
                        <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{ ml: 2, p: 0 }} onClick={() => setIsMenuOpen(state => !state)} >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <div className={`${isMenuOpen ? "block" : "hidden"} w-full absolute cursor-pointer divide-y border-solid border-t border-b border-white border-opacity-30 bg-menu`} style={{zIndex: 999}}>
                <div onClick={() => handleMenuClick("หน้าแรก", "/")} className={"p-3 flex items-center text-white font-bold text-lg border-white border-opacity-30"}>
                    <img alt='img-header-mobile-homme' className='forInactive' src={HomeIconInactive} style={{height: 20}} />
                    <span className='pt-1 pl-2'>หน้าแรก</span>
                </div>
                <div onClick={() => handleMenuClick("รายงานเด่นด้านทรัพยากรธรรมชาติ", '/highlight')} className={"p-3 flex items-center text-white font-bold text-lg border-white border-opacity-30"}>
                    <img alt='img-header-mobile-highlight' className='forInactive' src={HilightIconInactive} style={{height: 20}} />
                    <span className='pt-1 pl-2 flex-grow'>
                        รายงานเด่นด้านทรัพยากรธรรมชาติและสิ่งแวดล้อม
                    </span>
                    {highlightList && highlightList?.length && (subActiveMenuID === 'highlight') ? 
                        <RemoveIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(0)}} />
                    :
                        <AddIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(state => state === "highlight" ? 0 : "highlight")}} />
                    }
                </div>
                {highlightList && highlightList?.length ?   
                    highlightList?.map((item, itemIndex) => {
                        const sublist = item?.dashboards || [];
                        return sublist.map((sub, subIndex) => {
                            const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                            return (
                                <div key={`highlighr-item-mobile-${itemIndex}-${subIndex}`} onClick={() => window.open(isUrl ? sub['url'] : "#", isUrl ? "_blank" : "_self")} className={`${(subActiveMenuID === 'highlight') ? "flex" : "hidden"} p-2 items-center text-lg bg-white border-menu border-opacity-30`}>
                                    <div className='rounded-full bg-mainBtn group-hover:bg-white mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                    <span className='pt-1 pl-2'>
                                        {sub['title']}
                                    </span>
                                </div>
                            )
                        })
                    })
                : null}
                <div onClick={() => handleMenuClick("รายงานด้านทรัพยากรธรรมชาติ", '/topic')} className={"p-3 flex items-center text-white font-bold text-lg border-white border-opacity-30"}>
                    <img alt='img-header-mobile-incative' className='forInactive' src={TopicIconInactive} style={{height: 20}} />
                    <span className='pt-1 pl-2 flex-grow'>
                        รายงานด้านทรัพยากรธรรมชาติและสิ่งแวดล้อม
                    </span>
                    {topicList && topicList?.length && (subActiveMenuID === 'topic') ? 
                        <RemoveIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(0)}} />
                    :
                        <AddIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(state => state === "topic" ? 0 : "topic")}} />
                    }
                </div>
                {topicList && topicList?.length ?   
                    topicList?.map((item, itemIndex) => {
                        const sublist = item?.dashboards || [];
                        return sublist.map((sub, subIndex) => {
                            const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                            return (
                                <div key={`highlighr-item-mobile-${itemIndex}-${subIndex}`} onClick={() => window.open(isUrl ? sub['url'] : "#", isUrl ? "_blank" : "_self")} className={`${(subActiveMenuID === 'topic') ? "flex" : "hidden"} p-2 items-center text-lg bg-white border-menu border-opacity-30`}>
                                    <div className='rounded-full bg-mainBtn group-hover:bg-white mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                    <span className='pt-1 pl-2'>
                                        {sub['title']}
                                    </span>
                                </div>
                            )
                        })
                    })
                : null}
                {menuList?.map((menu, index) => {
                    const isService = (menu['title'] === 'บริการข้อมูล');
                    const genLink = (menu['title'] === 'อื่นๆ') ? "/about" : "#";
                    const menuID = menu?.id;
                    const isBigUrl = menu['url'] && ((menu['url']?.indexOf('http') === 0) || (menu['url']?.indexOf('www.') === 0));

                    return (
                        <React.Fragment key={`menu-${index}`}>
                            <div onClick={() => !isBigUrl && (genLink === "#") ? setSubActiveMenuID(state => state === menuID ? 0 : menuID) : isBigUrl && (genLink === "#") ? openUrl(menu['url']) : handleMenuClick(menu['title'], genLink)} className={"p-3 flex items-center text-white font-bold text-lg border-white border-opacity-30"}>
                                {isService ? <img alt='img-header-mobile-logo' className='forInactive' src={IconServiceInactive} style={{height: 20}} /> :  <img alt='img-header-mobile-logo' className='forInactive' src={OtherIconInactive} style={{height: 20}} />}
                                <span className='pt-1 pl-2 flex-grow'>
                                    {menu['title']}
                                </span>
                                {(subActiveMenuID === menuID) && !isBigUrl ? 
                                    <RemoveIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(0)}} />
                                :
                                    <AddIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(state => state === menuID ? 0 : menuID)}} />
                                }
                            </div>
                            {menuData?.level1 && menuData?.level1?.length && !isBigUrl && menuData.level1.find(item => item['parentId'] === menuID) ?
                                [...menuData.level1].sort((a, b) => (menuData.level2.filter(level2 => level2['parentId'] === a['id'])?.length < menuData.level2.filter(level2 => level2['parentId'] === b['id'])?.length) ? -1 : 0).filter(item => item['parentId'] === menuID)?.map((item, itemIndex) => {
                                    const sublist = menuData?.level2 ? menuData.level2.filter(level2 => level2['parentId'] === item['id']) : [];
                                    const isMainUrl = item['url'] && ((item['url']?.indexOf('http') === 0) || (item['url']?.indexOf('www.') === 0));
                                    if(!sublist || !sublist.length){
                                        return(
                                            <div key={`service-item-mobile-${itemIndex}`} onClick={() => (item['url'] === '/news') ? handleMenuClick(item['title'], '/news') : (item['url'] === '/project-detail') ? handleMenuClick(item['title'], '/about') : window.open(isMainUrl ? item['url'] : "#", isMainUrl ? "_blank" : "_self")} className={`${subActiveMenuID === menuID ? "flex" : "hidden"} p-2 items-center text-lg bg-white border-menu border-opacity-30`}>
                                                <div className='rounded-full bg-mainBtn group-hover:bg-white mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                                <span className='pt-1 pl-2'>
                                                    {item['title']}
                                                </span>
                                            </div>
                                        )
                                    }else if(sublist && sublist.length){
                                        return sublist.map((sub, subIndex) => {
                                            const isUrl = sub['url'] && ((sub['url']?.indexOf('http') === 0) || (sub['url']?.indexOf('www.') === 0));
                                            return (
                                                <div key={`service-sub-item-mobile-${subIndex}`} onClick={() => window.open(isUrl ? sub['url'] : "#", isUrl ? "_blank" : "_self")} className={`${subActiveMenuID === menuID ? "flex" : "hidden"} p-2 items-center text-lg bg-white border-menu border-opacity-30`}>
                                                    <div className='rounded-full bg-mainBtn group-hover:bg-white mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                                                    <span className='pt-1 pl-2'>
                                                        {sub['title']}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                })
                            : null}
                        </React.Fragment>
                    )
                })}
                <div onClick={() => {}} className={"p-3 flex flex-col text-white font-bold text-lg border-white border-opacity-30"} style={{backgroundColor: '#637e4a'}}>
                    <div className='flex flex-row items-center' onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(state => state === 'login' ? 0 : 'login')}}>
                        <Groups2OutlinedIcon style={{fontSize: 20}} />
                        <span className='pt-1 pl-2 flex-grow'>
                            สำหรับเจ้าหน้าที่ ทส.
                        </span>
                        {(subActiveMenuID === 'login') ?
                            <RemoveIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(0)}} />
                        :
                            <AddIcon className='mt-1' style={{fontSize: 20}} onClick={(e) => {e.stopPropagation(); setSubActiveMenuID(state => state === 'login' ? 0 : 'login')}} />
                        }
                    </div>
                    {(subActiveMenuID === 'login') && !authUser?.token ?
                        <form autoComplete="off" onSubmit={handleOnlogin}>
                            <Grid container>
                                <Grid item xs={12} className="pt-4">
                                    <TextField required id="email" type={"email"} label="" value={email} onChange={(e) => setEmail(e.target.value)} variant="outlined" fullWidth placeholder='Email' sx={{ 'input': { backgroundColor: '#fff', borderRadius: 1, fontSize: '1.6rem', fontWeight: 'bold', py: 0.6, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8, fontSize: '1.5rem' } }} />
                                </Grid>
                                <Grid item xs={12} className="pt-4">
                                    <TextField required id="password" type={"password"} label="" value={password} onChange={(e) => setPassword(e.target.value)} variant="outlined" fullWidth placeholder='Password' sx={{ 'input': { backgroundColor: '#fff', borderRadius: 1, fontSize: '1.6rem', fontWeight: 'bold', py: 0.6, verticalAlign: 'bottom' }, 'input::placeholder': { opacity: 0.8, fontSize: '1.5rem' } }} />
                                </Grid>
                                <Grid item xs={12} className="pt-4 pb-2">
                                    <Button variant='contained' type='submit' className='bg-primary' sx={{ fontSize: '1rem', fontWeight: 'bold', px: 4 }}>
                                        Login
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    : null}
                </div>
                {(subActiveMenuID === 'login') && authUser?.token ?
                    <React.Fragment> 
                        <div onClick={() => handleMenuClick(null, "/profile")} className={`${subActiveMenuID === 'login' ? "flex" : "hidden"} p-2 items-center text-lg bg-white border-menu border-opacity-30`}>
                            <div className='rounded-full bg-mainBtn group-hover:bg-white mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                            <span className='pt-1 pl-2'>
                                โปรไฟล์
                            </span>
                        </div>
                        <div onClick={handleLogout} className={`${subActiveMenuID === 'login' ? "flex" : "hidden"} p-2 items-center text-lg bg-white border-menu border-opacity-30`}>
                            <div className='rounded-full bg-mainBtn group-hover:bg-white mt-1 ml-4' style={{minHeight: "0.75rem", minWidth: "0.75rem"}}></div>
                            <span className='pt-1 pl-2'>
                                ออกจากระบบ
                            </span>
                        </div>
                    </React.Fragment>
                : null}
            </div>
            {!isAboutPage && !isNewsPage ?
                <div className='bg-gray-900 bg-no-repeat bg-top bg-cover pt-6' style={{ width: '100%', backgroundImage: `url(${ImgCloud}), url(${BannerMain})`, backgroundPosition: "center" }}>
                    <div className='bg-no-repeat' style={{ width: '100%' }}>
                        <div className='my-4 max-w-xl w-11/12 mx-auto pt-10 pb-4 md:py-24 lg:py-40 xl:py-48 text-white text-3xl sm:text-5xl flex flex-col text-center font-bold' onMouseOver={clearSubMenu}>
                            <span>
                                ระบบเชื่อมโยงและแสดงข้อมูล
                            </span>
                            <span>
                                ทรัพยากรธรรมชาติและสิ่งแวดล้อม
                            </span>
                        </div>
                        <div className='py-16'></div>
                    </div>
                </div>
            : null}
            <ModalPDPA open={modalPDPAOpen} handleClose={() => setModalPDPAOpen(false)} />
        </div>
    );
};

export default HeaderMobile;