import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid, useMediaQuery, Grow } from "@mui/material";

import ImgMap2 from '../assets/images/map2.png';
import ImgEgle from '../assets/images/Image_58.png';
import ImgBird from '../assets/images/Image_54.png';
import ImgGroup1 from '../assets/images/main-group-1';
import ImgGroup2 from '../assets/images/main-group-2.png';
import ImgGroup3 from '../assets/images/main-group-3.png';

const HomePage = () => {
    const navigate = useNavigate();
    const [group1Height, setGroup1Height] = useState(0);
    const isLarge = useMediaQuery(theme=>theme.breakpoints.up('md'));
    const isLarger = useMediaQuery(theme=>theme.breakpoints.up('lg'));
    const isXLarger = useMediaQuery(theme=>theme.breakpoints.up('xl'));
    const isXXLarger = useMediaQuery('(min-width:1900px)');

    const setHeight = () => {
        const getHeigth = document.getElementById("main-hihlght-1")?.offsetHeight;
        setGroup1Height(isLarge ? getHeigth : 355)
    }

    useEffect(() => {
        setHeight()
    }, [])

    window.addEventListener('resize', () => {
        setHeight()
    });
    
    window.addEventListener('orientationchange', () => {
        setHeight()
    });
    
    return (
        <Grid container maxWidth={"lg"} className="mx-auto">
            <Grid item md={5} lg={6} className="hidden md:flex justify-end" style={{borderRight: '1px solid rgba(161,177,137,1)'}}>
                <div id="main-hihlght-1" className="my-auto font-bold text-end pt-6 pr-16" style={{ color: 'rgba(161,177,137,1)' }}>
                    <span className="text-6xl lg:text-8xl highlightLine">
                        รายงานเด่น
                    </span>
                    <br/>
                    <span className="text-5xl lg:text-7xl highlightLine">
                        ด้านทรัพยากร<br/>ธรรมชาติและ<br/>สิ่งแวดล้อม
                    </span>
                    <p className="text-2p5xl font-normal leading-5 lg:leading-7 pt-4 highlightSubText">
                        รายงานข้อมูลและสถานการณ์สำคัญ<br/>ด้านทรัพยากรธรรมชาติและสิ่งแวดล้อม<br/>ที่ผ่านการวิเคราะห์ และแสดงผลในรูปแบบ<br/>Virtualization อย่างน่าสนใจ
                    </p>
                    <Button variant="contained" color="secondary" className="!font-bold text-2p5xl !rounded-xl !px-8 py-1 lg:py-0 !mt-6" onClick={() => navigate("/highlight")}>
                        ดูรายงานเด่นทั้งหมด
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12} md={7} lg={6} className="flex justify-center md:justify-start">
                <div className="flex justify-center md:pl-8 lg:pl-16 transition duration-500 ease-in-out transform hover:scale-110">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
                        <img src={ImgGroup1} className="w-full" style={{ maxHeight: group1Height }} />
                    </Grow>
                </div>
            </Grid>
            <Grid item xs={12} className="block md:hidden">
                <div id="main-hihlght-1" className="my-auto font-bold text-center pt-6" style={{ color: 'rgba(161,177,137,1)' }}>
                    <span className="text-4xl sm:text-5xl highlightLine">
                        รายงานเด่นด้านทรัพยากร
                    </span>
                    <br/>
                    <span className="text-4xl sm:text-5xl highlightLine">
                        ธรรมชาติและสิ่งแวดล้อม
                    </span>
                    <br/>
                    <Button variant="contained" color="secondary" className="!font-bold text-2p5xl !rounded-xl !px-8 py-1 lg:py-0 !mt-6" onClick={() => navigate("/highlight")}>
                        ดูรายงานเด่นทั้งหมด
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12} className="py-10 sm:py-16">
                <div className="border-t-2 border-dashed" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
            </Grid>
            <Grid item xs={12} md={6} className="flex justify-center">
                <div className="flex justify-center md:pr-8 lg:pr-16 transition duration-500 ease-in-out transform hover:scale-110">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
                        <img src={ImgGroup2} style={{width: '80%'}} />
                    </Grow>
                </div>
            </Grid>
            <Grid item xs={12} className="block md:hidden">
                <div className="font-bold pt-6 text-center" style={{ color: 'rgba(161,177,137,1)' }}>
                    <span className="text-4xl sm:text-5xlhighlightLine">
                        รายงานด้านทรัพยากร
                    </span>
                    <br/>
                    <span className="text-4xl sm:text-5xlhighlightLine">
                        ธรรมชาติและสิ่งแวดล้อม
                    </span>
                    <br/>
                    <Button variant="contained" color="secondary" className="!font-bold text-2p5xl !rounded-xl !px-8 py-1 lg:py-0 !mt-6"  onClick={() => navigate("/topic")}>
                        ดูรายงานทั้งหมด
                    </Button>
                </div>
            </Grid>
            <Grid item xs={6} className="hidden md:flex justify-start" style={{borderLeft: '1px solid rgba(161,177,137,1)'}}>
                <div className="my-auto font-bold pt-6 pl-16" style={{ color: 'rgba(161,177,137,1)' }}>
                    <span className="text-5xl lg:text-7xl highlightLine">
                        รายงานด้าน<br/>ทรัพยากร<br/>ธรรมชาติและ<br/>สิ่งแวดล้อม
                    </span>
                    <p className="text-2p5xl font-normal leading-5 lg:leading-7 pt-4 highlightSubText">
                        รายงานข้อมูลสถิติด้านทรัพยากรธรรมชาติและสิ่งแวดล้อม 5 ด้าน<br/>ประกอบด้วย ด้านทรัพยากรน้ำ ด้านทรัพยากรทางบก<br/>ด้านสิ่งแวดล้อม ด้านทรัพยากรทางทะเลและชายฝั่ง<br/>และด้านความหลากหลายทางชีวภาพ
                    </p>
                    <Button variant="contained" color="secondary" className="!font-bold text-2p5xl !rounded-xl !px-8 py-1 lg:py-0 !mt-6"  onClick={() => navigate("/topic")}>
                        ดูรายงานทั้งหมด
                    </Button>
                </div>
                <img src={ImgBird} className="absolute" style={{ marginLeft: isLarger ? 400 : 300, paddingBottom: isLarger ? 750 : 550, width: 140 }} />
                {isLarger ? <img src={ImgBird} className="absolute" style={{ marginLeft: 480, paddingTop: 200, width: 90 }} /> : null}
            </Grid>
            <Grid item xs={12} className="py-10 sm:py-16">
                <div className="border-t-2 border-dashed" style={{borderColor: 'rgba(112,112,112,1)'}}></div>
            </Grid>
            <Grid item md={5} lg={6} className="hidden md:flex justify-end" style={{borderRight: '1px solid rgba(161,177,137,1)'}}>
                <div id="main-hihlght-1" className="my-auto font-bold text-end pt-6 pr-16" style={{ color: 'rgba(161,177,137,1)' }}>
                    <span className="text-5xl lg:text-7xl highlightLine">
                        บริการข้อมูล<br/>แผนที่ทรัพยากร<br/>ธรรมชาติและ<br/>สิ่งแวดล้อม<br/>GIS Web Portal
                    </span>
                    <p className="text-2p5xl font-normal leading-5 lg:leading-7 pt-4 highlightSubText">
                        แสดงชั้นข้อมูลแผนที่ด้านทรัพยากรธรรมชาติ<br/>และสิ่งแวดล้อมของประเทศไทย จำนวน 5 ด้าน<br/>ประกอบด้วย ด้านทรัพยากรน้ำ ด้านทรัพยากรทางบก<br/>ด้านสิ่งแวดล้อม ด้านทรัพยากรทางทะเลและชายฝั่ง<br/>และด้านความหลากหลายทางชีวภาพ
                    </p>
                    <Button variant="contained" color="secondary" className="!font-bold text-2p5xl !rounded-xl !px-8 py-1 lg:py-0 !mt-6" onClick={() => window.open("https://gis.mnre.go.th/portal/apps/sites/#/mnre-data-integration-system", "_blank")}>
                        <div className="flex flex-col justify-center items-center">
                            <div>
                                เปิดแผนที่
                            </div>
                            <div className="pb-3 transition duration-500 ease-in-out transform hover:scale-110">
                                <img src={ImgMap2} className="w-8 lg:w-12" />
                            </div>
                        </div>
                    </Button>
                </div>
            </Grid>
            <Grid item xs={12} md={7} lg={6} className="flex justify-center items-center">
                <div className="flex justify-center md:pl-8 lg:pl-16 transition duration-500 ease-in-out transform hover:scale-110">
                    <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 1000 } : {})}>
                        <img src={ImgGroup3} className="w-full" style={{ maxHeight: '100%', height: isLarger ? 800 : 600 }} />
                    </Grow>
                </div>
                <img src={ImgEgle} className="absolute" style={{ marginLeft: isLarger ? 180 : 140, paddingBottom: isLarger ? 750 : 550, width: isLarger ? 120 : 80 }} />
            </Grid>
            <Grid item xs={12} className="block md:hidden">
                <div className="font-bold pt-6 text-center" style={{ color: 'rgba(161,177,137,1)' }}>
                    <span className="text-4xl sm:text-5xlhighlightLine">
                        บริการข้อมูลแผนที่ทรัพยากรธรรมชาติและ สิ่งแวดล้อม GIS Web Portal
                    </span>
                    <br/>
                    <Button variant="contained" color="secondary" className="!font-bold text-2p5xl !rounded-xl !px-8 py-1 lg:py-0 !mt-6" onClick={() => window.open("https://gis.mnre.go.th/portal/apps/sites/#/mnre-data-integration-system", "_blank")}>
                        <div className="flex justify-center items-center">
                            <img src={ImgMap2} className="w-6 mr-2" />
                            เปิดแผนที่
                        </div>
                    </Button>
                </div>
            </Grid>
            {isLarger ? <img src={ImgBird} className="absolute" style={{ top: isXLarger ? 2000 : 1920, marginLeft: `40px`, width: 160 }} /> : null}
            {isLarge ? <img src={ImgEgle} className="absolute" style={{ top: 160, left: `calc(100vw / 2)`, width: 80 }} /> : null}
            {isXXLarger ? <img src={ImgEgle} className="absolute" style={{ top: 700, left: `30px`, width: 140, zIndex: 2 }} /> : null}
            {isXLarger ? <img src={ImgEgle} className="absolute" style={{ top: 1000, left: isXXLarger ? `calc(100vw / 4.2)` : `calc(100vw / 5.5)`, width: 100, zIndex: 2 }} /> : null}
        </Grid>
    );
}

export default HomePage;