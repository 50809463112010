import React, { Suspense } from 'react';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, Slide } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import Routes from './routes';
import THEME from './assets/theme';
import BackDrop from './components/backDrop';
import Snackbar from './components/snackbar';

const BASE_NAME = process.env.REACT_APP_BASE_NAME;

function App() {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={Slide} autoHideDuration={2000} >
            <ThemeProvider theme={THEME}>
                <CssBaseline />
                <BrowserRouter basename={BASE_NAME}>
                {/* <BrowserRouter> */}
                {/* <HashRouter>  // for non indexing*/}
                    <Snackbar />
                    <BackDrop />
                    <Suspense>
                        <Routes />
                    </Suspense>
                {/* </HashRouter> */}
                </BrowserRouter>
            </ThemeProvider>
        </SnackbarProvider>
    );
}

export default App;