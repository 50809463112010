const palette = {
    primary: {
        dark: '#192321',
        main: '#243330',
        light: '#4f5b59',
        contrastText: "#fff"
    },
    secondary: {
       dark: '#465834',
       main: '#647e4b',
       light: '#83976f',
       contrastText: "#fff"
    },
    menu: {
       dark: '#3c4d3c',
       main: '#576f56',
       light: '#788b77',
       contrastText: "#fff"
    },
    clearButton: {
        dark: '#474a4f',
        main: '#666B71',
        light: '#84888d',
        contrastText: "#fff"
    },
    copyButton: {
        dark: '#9c9c9c',
        main: '#E0E0E0',
        light: '#e6e6e6',
        contrastText: "#4B4B4B"
    },
    error: {
        dark: '#a51b08',
        main: '#ED270C',
        light: '#f0523c',
        contrastText: "#fff"
    },
    adminBtn: {
        dark: "#455833",
        main: "#637e4a",
        light: "#82976e",
        contrastText: "#fff"
    }
};

export default palette;